import { Injectable, EventEmitter } from '@angular/core';
import { LocalStorageService } from './localStorage.service';
import { Company, Contact, PasswordPolicyConfig, UserLanguage } from '../shared/generalInterfaces';
import { HttpParams } from '@angular/common/http';
import { EupHttpHandler } from './eupHttpHandler.service';
import { BehaviorSubject, from, Observable, of, Subject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { EupRoutesService } from './eupRoutes.service';
import { TranslateService } from '@ngx-translate/core';
import { RoleTypeEnum, PrintFormat } from '../shared/enums';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { BsDatepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import {
	enGbLocale as en,
	deLocale as de,
	esLocale as es,
	frLocale as fr,
	itLocale as it,
	jaLocale as ja,
	koLocale as ko,
	zhCnLocale as zhCn,
	trLocale as tr,
	ptBrLocale as ptBr,
	thLocale as th,
	ruLocale as ru,
} from 'ngx-bootstrap/locale';
import { GeneralExportSettingsData } from '../shared/settings.service';
import { SoftwareOptionsService } from '../core/softwareOptions.service';
import { Consts } from '@shared/consts';
import { RegulatoryConfigurationState, RegulatoryConfigurationStore } from '../settings/state/regulatory-configuraton.store';
import { tap } from 'rxjs/operators';
import { Utils } from '@shared/utils.service';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';

export class ContextParams {
	companyId: number;
	doctorId: number;
	softwareOptions: number[];
	locale: UserLanguage;
}

export class GlobalUISettings {
	public bsDatePickerConfig: BsDatepickerConfig;
}

//Don't use GlobalSettingsService
//Should be treated as deprecated
/**
 * @deprecated This service is deprecated and should be replaced with direct usage of ShellContextService.
 */
@Injectable()
export class GlobalSettingsService {
	private settings: GlobalSettings;
	private storage: Storage;
	private http: EupHttpHandler;

	public uiSettings: GlobalUISettings;
	public prevRoleType: RoleTypeEnum;

	contextChanged = new EventEmitter<ContextParams>();
	initializeCalled$ = new Subject<void>();
	contactIdChanged$ = new Subject<number>();

	private locales = [
		{ code: 'en', bsCode: 'engb', locale: en },
		{ code: 'de', locale: de },
		{ code: 'es', locale: es },
		{ code: 'fr', locale: fr },
		{ code: 'it', locale: it },
		{ code: 'ja', locale: ja },
		{ code: 'ko', locale: ko },
		{ code: 'zh', locale: zhCn },
		{ code: 'th', locale: th },
		{ code: 'tr', locale: tr },
		{ code: 'pt', locale: ptBr },
		{ code: 'ru', locale: ru },
	];

	public isDoctorSetReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		private translateService: TranslateService,
		localStorageService: LocalStorageService,
		http: EupHttpHandler,
		private eupRoutesService: EupRoutesService,
		private softwareOptionsService: SoftwareOptionsService,
		private bsLocaleService: BsLocaleService,
		private regulatoryConfigurationStore: RegulatoryConfigurationStore,
		private shellContextService: ShellContextService
	) {
		this.storage = localStorageService.storage;
		this.http = http;
		this.uiSettings = new GlobalUISettings();
		this.prevRoleType = null; 
	}

	initialize(settings: GlobalSettings): boolean {
		const companyChanged = this.settings && settings.selectedCompanyId !== this.settings.selectedCompanyId;
		const contactChanged = this.settings && settings.contactId !== this.settings.contactId;

		if (companyChanged || (this.settings && this.prevRoleType === null)) {
			this.prevRoleType = this.settings.roleType;
		}

		let originalLanguage: string;
		let currentLanguage: string;

		if (this.settings && this.settings.selectedLanguage && this.settings.selectedLanguage.code) {
			originalLanguage = this.settings.selectedLanguage.code;
		}

		if (settings && settings.selectedLanguage && settings.selectedLanguage.code) {
			currentLanguage = settings.selectedLanguage.code;
		}

		const languageChanged =
			originalLanguage !== currentLanguage ||
			(this.translateService.currentLang !== undefined && this.translateService.currentLang !== currentLanguage);

		this.settings = settings;
		this.storage.setItem(Consts.Storage.Settings, JSON.stringify(settings));
		this.initializeCalled$.next();

		if (contactChanged) {
			this.contactIdChanged$.next(this.settings.contactId);
		}

		if (companyChanged) {
			const params = new ContextParams();
			params.companyId = +this.settings.selectedCompanyId;
			params.doctorId = +this.settings.selectedDoctorId;
			this.contextChanged.emit(params);
		}

		if (languageChanged) {
			this.translateService.use((settings.selectedLanguage && settings.selectedLanguage.code) || 'en-US');
		}

		this.updateDatePickerLocale();

		return companyChanged;
	}

	setCompanyAndDefaultDoctor(companyId: number): Observable<any> {
		const settings = this.get();

		settings.selectedCompanyId = companyId;
		settings.hasFullOrdersVisibility = settings.companies.filter((c) => c.id === +companyId)[0].currentUserHasFullOrdersVisibility;
		if (!settings.hasFullOrdersVisibility) {
			settings.selectedDoctorId = settings.contactId;
		}
		// we need to clear GeneralExportSettingsData because it is company related.
		settings.generalExportSettingsData = null;

		this.initialize(settings);

		if (settings.hasFullOrdersVisibility) {
			return from(
				this.getContactsForCompany(companyId).forEach((data: Contact[]) => {
					settings.selectedDoctorId = settings.contactId;

					const doctorExists = data.filter((c: Contact) => c.contactId === settings.contactId).length === 1;

					if (!doctorExists && data.length > 0) {
						settings.selectedDoctorId = data[0].contactId;
					}
					this.setSelectedDoctor(settings.selectedCompanyId, settings.selectedDoctorId).pipe(
						switchMap(_ => this.softwareOptionsService.getCompanySoftwareOptions(companyId))).subscribe((res) => {
						this.setDoctor(settings.selectedDoctorId);
						this.setCompanySoftwareOptions(res);
						this.shellContextService.updateContext(context => {
							context.bizCtx.companyId = companyId;
						});
					});
				})
			);
		} else {
			this.setSelectedDoctor(settings.selectedCompanyId, settings.contactId).pipe(
				switchMap(_ => this.softwareOptionsService.getCompanySoftwareOptions(companyId))).subscribe((res) => {
					this.setDoctor(settings.contactId);
					this.setCompanySoftwareOptions(res);
					this.shellContextService.updateContext(context => {
						context.bizCtx.companyId = companyId;
					});
			});
		}

		return of(1);
	}

	getCurrentGlobalSettings(): GlobalSettings {
		return this.settings;
	}

	setCompanySoftwareOptions(companySoftwareOptions: number[]) {
		const settings = this.get();		
		settings.companySoftwareOptions = companySoftwareOptions;
		const params = new ContextParams();
		params.softwareOptions = companySoftwareOptions;

		if (!this.initialize(settings)) {
			params.companyId = settings.selectedCompanyId;
			params.doctorId = settings.selectedDoctorId;
			this.contextChanged.emit(params);
		}
	}

	clear(): void {
		this.storage.clear();
		this.settings = undefined;
	}

	setDoctor(doctorId: number): void {
		this.shellContextService.updateContext(context => {
			context.bizCtx.contactId = doctorId;
		});

		const settings = this.get();
		settings.selectedDoctorId = doctorId;

		if (!this.initialize(settings)) {
			const params = new ContextParams();
			params.companyId = settings.selectedCompanyId;
			params.doctorId = doctorId;
			this.contextChanged.emit(params);
		}

		this.setLoggedInUserIdsPairedStatus();
		this.isDoctorSetReady$.next(true);
	}

	setSelectedDoctor(companyId: number, doctorId: number): Observable<any> {
		const setDoctoUrl = this.eupRoutesService.userSettings.setDoctor(companyId, doctorId);
		return this.http.post(setDoctoUrl);
	}

	setLanguage(language: UserLanguage): void {
		const settings = this.get();
		settings.selectedLanguage = language;

		this.initialize(settings);
		this.updateDatePickerLocale();
		const params = new ContextParams();
		params.companyId = settings.selectedCompanyId;
		params.doctorId = settings.selectedDoctorId;
		params.softwareOptions = settings.companySoftwareOptions;
		params.locale = language;

		this.contextChanged.emit(params);
	}

	getLanguage(): UserLanguage {
		return this.get().selectedLanguage;
	}

	setDateFormat(format: string): void {
		const settings = this.get();
		settings.dateFormat = format;
		this.initialize(settings);
		this.updateDatePickerLocale();
	}

	setGeneralExportSettingsData(generalExportSettingsData: GeneralExportSettingsData) {
		const settings = this.get();
		settings.generalExportSettingsData = generalExportSettingsData;
		this.initialize(settings);
	}

	setPrintFormat(printFormat: PrintFormat) {
		const settings = this.get();
		settings.printFormat = printFormat;
		this.initialize(settings);
	}

	getPrintformat() {
		const settings = this.get();
		return settings.printFormat;
	}

	getContactsForCompany(companyId: number): Observable<Contact[]> {
		const settings = this.get();
		const company = settings.companies.filter((x) => x.id === companyId)[0];
		if (company && company.contacts) {
			return of(company.contacts);
		}

		const params = new HttpParams().set('companyId', companyId.toString());

		return this.http.get(this.eupRoutesService.logonAs.getDoctorsByCompanyId, { params: params }).pipe(
			map((res) => {
				company.contacts = res as Contact[];
				this.initialize(settings);
				return company.contacts;
			})
		);
	}

	isContactLinkedToChineseCompany(contactId: number): Observable<boolean> {
		const params = new HttpParams().set('contactId', contactId.toString());
		return this.http.get(this.eupRoutesService.contact.isContactLinkedToChineseCompany, { params: params }).pipe(
			map((res) => {
				return res as boolean;
			})
		);
	}

	setLoggedInUserIdsPairedStatus(): void {
		this.getLoggedInUserIdsPairedStatus().subscribe((res) => {
			this.settings.isLoggedInDoctorIdsPaired = res;
		});
	}

	getLoggedInUserIdsPairedStatus(): Observable<boolean> {
		return this.http.get(this.eupRoutesService.idsPairing.getIsDoctorPaired()).pipe(
			map((res) => {
				return res as boolean;
			})
		);
	}

	get(): GlobalSettings {
		if (!this.settings) {
			this.settings = JSON.parse(this.storage.getItem(Consts.Storage.Settings)) as GlobalSettings;
		}
		return this.settings;
	}

	getFirstCompany(): Company {
		const companies = this.get().companies;
		if (companies.length === 0) {
			throw new Error('No companies exist in globalSettings');
		}
		return companies[0];
	}

	getSelectedCompany(): Company {
		const settings = this.get();
		return settings.companies?.find(c => c.id === settings.selectedCompanyId);
	}

	hasElementScanner(): boolean {
		return !!this.settings.companies.filter((c) => c.id === +this.settings.selectedCompanyId)[0].highestScannerVersion;
	}

	public updateDatePickerLocale() {
		if (!this.uiSettings.bsDatePickerConfig) {
			this.uiSettings.bsDatePickerConfig = new BsDatepickerConfig();
		}
		this.uiSettings.bsDatePickerConfig.containerClass = 'theme-blue';
		this.uiSettings.bsDatePickerConfig.showWeekNumbers = false;
		if (this.settings.selectedLanguage && this.settings.selectedLanguage.code.length > 1) {
			const langCode = this.settings.selectedLanguage.code.slice(0, 2);
			const currentLocale = this.locales.find((v) => v.code === langCode);
			const currentLocaleCode = currentLocale.bsCode ? currentLocale.bsCode : currentLocale.code;
			if (currentLocale) {
				defineLocale(currentLocaleCode, currentLocale.locale);
				Object.assign(this.uiSettings.bsDatePickerConfig, { locale: currentLocale.code });
				this.bsLocaleService.use(currentLocaleCode);
			}
		}

		if (this.settings.dateFormat) {
			this.uiSettings.bsDatePickerConfig.dateInputFormat = this.settings.dateFormat;
		}
	}

	rolePath(): string {
		const globalSettingsVar = this.get();
		if (!globalSettingsVar) {
			return '';
		}
		const roleType = globalSettingsVar.roleType;
		switch (roleType) {
			case RoleTypeEnum.Distributor:
				return 'distributors';
			case RoleTypeEnum.ManagementCompany:
				return 'managementcompanies';
			case RoleTypeEnum.Doctor:
				return 'doctors';
			case RoleTypeEnum.Lab:
				return 'labs';
			case RoleTypeEnum.Personnel:
				return 'personnels';
			default:
				return '';
		}
	}

	getCompanyRegion(companyId: number): Observable<string> {
		const params = new HttpParams().set('companyId', companyId.toString());
		return this.http.get(this.eupRoutesService.userSettings.getCompanyRegionUrl, { params: params });
	}

	setPatientConsent(isPatientConsentApproved: boolean) {
		const settings = this.get();
		settings.isPatientConsentApproved = isPatientConsentApproved;
		this.storage.setItem(Consts.Storage.Settings, JSON.stringify({ ...settings, isPatientConsentApproved }));
	}

	getRegulatoryConfiguration(companyId: number): Observable<RegulatoryConfigurationState> {
		const params = new HttpParams().set('companyId', companyId.toString());
		return this.http.get(this.eupRoutesService.userSettings.getRegulatoryConfigurationUrl, { params }).pipe(
			tap((result: RegulatoryConfiguration) => {
				const dateOfBirthFormat = Utils.getDateOfBirthFormat(this.get().dateFormat, result?.dobMask);
				if (!result) {
					this.regulatoryConfigurationStore.update({ dateOfBirthFormat });
					return;
				}

				const regulatoryConfiguration = {
					isChartNumberDisabled: result.isChartNumberDisabled,
					maxPatientLastNameLength: result.maxPatientLastNameLength,
					dateOfBirthFormat,
					dateOfBirthMask: result.dobMask,
				};
				this.regulatoryConfigurationStore.update(regulatoryConfiguration);
			})
		);
	}

	hasRole(role: RoleTypeEnum): boolean {
		const roleType = this.get()?.roleType;
		return roleType && roleType === role;
	}
}

export class GlobalSettings {
	username: string;
	loginName: string;
	companies: Company[];
	ordersPageSize: number;
	labOrdersPageSize: number;
	contactId: number;
	selectedDoctorId: number;
	patientsPageSize: number;
	contactsPageSize: number;
	invitationsPageSize: number;
	selectedCompanyId: number;
	hasFullOrdersVisibility: boolean;
	selectedLanguage: UserLanguage;
	dateFormat: string;
	iTeroSiteUrl: string;
	passwordPolicyConfig: PasswordPolicyConfig;
	roleType: RoleTypeEnum;
	loginEmail: string;
	primaryEmail: string;
	generalExportSettingsData: GeneralExportSettingsData;
	printFormat: PrintFormat;
	isLoggedInDoctorIdsPaired: boolean;
	jwtAccessToken?: string;
	isPatientConsentApproved: boolean;
	companySoftwareOptions: number[];
	shouldRedirect: true;
	redirectUrl: string;
}

export class RegulatoryConfiguration {
	dobMask: string;
	maxPatientLastNameLength: number;
	isChartNumberDisabled: boolean;
	shouldGenerateChartNumber: boolean;
}
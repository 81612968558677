import { Directive, HostListener, Input, OnDestroy } from '@angular/core';
import { ShellContextService } from 'app/services/shell-context/shell-context.service';
import { TimberService } from '@logging/timber.service';
import { Utils } from '@shared/utils.service';
import { firstValueFrom, Subscription } from 'rxjs';
import { BiAction, BiObjectType, ExistingObjectIds } from '@logging/interfaces/bi.enums';
import { BiFeatureWrapperService } from '@logging/bi-feature-wrapper.service';

@Directive({
	selector: '[logClick]',
})
export class LogClickDirective implements OnDestroy {
	orderSubscription: Subscription;
	private clicked = false;
	private timeoutId: any;

	constructor(
		private shellContextService: ShellContextService,
		private timberService: TimberService,
		private utils: Utils,
		private biFeatureWrapperService: BiFeatureWrapperService
	) {}
	@Input() objectType: BiObjectType = BiObjectType.Button;
	@Input() action: BiAction = BiAction.Clicked;
	@Input() value!: string;

	@HostListener('click', ['$event'])
	async handleClick(event: MouseEvent) {
		Promise.resolve().then(async () => {
			if (!this.clicked) {
				this.clicked = true;
				const clickedElement = event.target as HTMLElement;
				const context = await firstValueFrom(this.shellContextService.getContext());

				const orderId = context?.bizCtx?.orderId ?? context?.bizCtx?.rxId;
				const selectedValue = this.value ? this.value : orderId ? `orderId: ${orderId.toString()}` : null;

				this.timberService.userActionEvent({
					locationParentObject: this.biFeatureWrapperService.getCurrentPage(),
					objectName: this.getElementId(clickedElement),
					objectType: this.objectType,
					action: this.action,
					selectedValue: selectedValue,
				});

				this.timeoutId = setTimeout(() => (this.clicked = false), 0);
			}
		});
	}

	ngOnDestroy(): void {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}
		this.utils.clearObservablesSubscriptions(this);
	}

	private getElementId(element: HTMLElement): string {
		let currentElement = element;

		while (currentElement) {
			if (currentElement.id) {
				const cleanId = currentElement.id.replace(/[-_]\d+$/, '');
				return this.replaceId(cleanId);
			}
			currentElement = currentElement.parentElement;
		}
	}

	private replaceId(id: string): string {
		if (id in ExistingObjectIds) {
			return ExistingObjectIds[id as keyof typeof ExistingObjectIds];
		}
		return id;
	}
}

import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { SpinnerService } from './spinner/spinner.service';
import { StatusCode } from '@shared/generalInterfaces';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { TimberService } from '@logging/timber.service';
import { IApiReportEvent } from '@logging/interfaces/bi.events';
import { ApiMethods } from '@logging/interfaces/bi.enums';

@Injectable()
export class EupHttpHandler {
	private ngUnsubscribe: Subject<void> = new Subject<void>();

	constructor(
		private httpService: HttpClient,
		private router: Router,
		private spinnerService: SpinnerService,
		private location: Location,
		private timberService: TimberService,
	) {}

	post(url: string, body?: any, options?: any, navigateOnFailure: boolean = true, triggerSpinner: boolean = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();
		
		return this.httpService.post(url, body, { ...options, withCredentials: true }).pipe(
			takeUntil(this.ngUnsubscribe),
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Post, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure))
		);
	}

	postWithoutCancel(url: string, body?: any, options?: any, navigateOnFailure: boolean = true, triggerSpinner: boolean = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService.post(url, body, { ...options, withCredentials: true }).pipe(
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Post, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure))
		);
	}

	get<T>(url: string, options?: any, navigateOnFailure = true, triggerSpinner = true, withCredentials = true, skipEupHttpErrorHandler = false): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService.get<Partial<T>>(url, { ...options, withCredentials: withCredentials }).pipe(
			takeUntil(this.ngUnsubscribe),
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Get, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure, true, skipEupHttpErrorHandler))
		);
	}

	//This is a temporary solution for not cancelling request
	//Use only in case of getting feature toggles request
	getWithoutCancel(url: string, options?: any, navigateOnFailure = true, triggerSpinner = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService.get(url, { ...options, withCredentials: true }).pipe(
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Get, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure, false))
		);
	}

	delete(url: string, options?: any, navigateOnFailure = true, triggerSpinner = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService.delete(url, { ...options, withCredentials: true }).pipe(
			takeUntil(this.ngUnsubscribe),
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Delete, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure))
		);
	}

	deleteWithoutCancel(url: string, options?: any, navigateOnFailure = true, triggerSpinner = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService.delete(url, { ...options, withCredentials: true }).pipe(
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Delete, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure))
		);
	}

	patch(url: string, body?: any, options?: any, navigateOnFailure: boolean = true, triggerSpinner: boolean = true): Observable<any> {
		if (triggerSpinner) {
			this.spinnerService.start();
		}

		const startingTime = new Date().getTime();

		return this.httpService.patch(url, body, { ...options, withCredentials: true }).pipe(
			takeUntil(this.ngUnsubscribe),
			tap((res) => this.handleResponse(res, triggerSpinner, startingTime, url, ApiMethods.Patch, options?.params)),
			catchError((e) => this.handleError.call(this, e, navigateOnFailure))
		);
	}

	put(url: string, body?: any, options?: any): Observable<any> {
		const startingTime = new Date().getTime();

		return this.httpService.put(url, body, { ...options, withCredentials: true }).pipe(
			takeUntil(this.ngUnsubscribe),
			tap((res) => this.handleResponse(res, false, startingTime, url, ApiMethods.Put, options?.params)),
			catchError((e) => this.handleError.call(this, e, false))
		);
	}

	private handleResponse(res: any, triggerSpinner: boolean, startingTime: number, url: string, apiAction: ApiMethods, parameters: any) {
		if (triggerSpinner) {
			this.spinnerService.stop();
		}

		this.logBi(apiAction, url, parameters, res);
		// calculate timing
		if (startingTime && url) {
			const timeElapsed = new Date().getTime() - startingTime;
		}

		if (res && res.headers) {
			const redirectToUrl = res.headers.get('RedirectRoute');
			const externalUrl = res.headers.get('ExternalRedirect');

			if (redirectToUrl) {
				this.router.navigate([redirectToUrl.toLowerCase()]);
			}
			if (externalUrl) {
				window.location.href = externalUrl;
			}
		}
	}

	handleError(err: any, navigateOnFailure: boolean, cancellable: boolean = true, skipEupHttpErrorHandler: boolean = false): Observable<any> {
		// Check if the error should be handled here or rethrown
		if (err?.skipEupHttpErrorHandler || skipEupHttpErrorHandler) {
			return throwError(() => err);
		}
	
		if (cancellable) {
			this.ngUnsubscribe.next();
		}

		this.spinnerService.stop();
		const status = err.status;

		if (navigateOnFailure) {
			switch (status) {
				case StatusCode.Unauthorized: {
					const currentUrl = this.location.path();

					// if we are not coming from login page - redirect
					if (currentUrl.indexOf('login') === -1) {
						const params = this.router.parseUrl(currentUrl).queryParams;
						let navigationExtras = {};

						if (params?.returnUrl) {
							navigationExtras = { queryParams: params };
						} else if (currentUrl) {
							navigationExtras = { queryParams: { returnUrl: currentUrl } };
						}

						this.router.navigate(['/login'], navigationExtras);
					} else {
						this.spinnerService.reset();
						return throwError(() => err);
					}
					break;
				}
				case StatusCode.BadRequest:
				case StatusCode.Forbidden:
				case StatusCode.ContentNotFound:
				case StatusCode.Timeout:
				case StatusCode.InternalServerError:
					// These errors are handled by the ErrorHandler
					// which catches the error thrown at the end of this function
					break;
				case StatusCode.Redirect:
					this.router.navigate([err.headers.get('RedirectRoute').toLowerCase()]);
					break;
				default:
					break;
			}
		}
		return throwError(() => err);
	}
	private logBi(action: ApiMethods, url: string, parameters: string = '', result: any = {}): void {
		const res = this.checkStatus(action, result);
		this.timberService.apiReportEvent({
			name: 'app-settings-service',
			action: action,
			url: url,
			parameters: parameters,
			caller: '',
			result: res
		} as IApiReportEvent);
	};
	
	private checkStatus(action: ApiMethods, result: any = {}): { status: string, options: { fileSize: string, timeToDownloadMs: number } } {
		let status: string;
		if (result == null) {
			status = '';
		} else {
			switch (action) {
				case ApiMethods.Post:
				case ApiMethods.Delete:
					status = result.status;
					break;
				case ApiMethods.Get:
					status = '200';
					break;
				case ApiMethods.Put:
				case ApiMethods.Patch:
					const parsedRes = JSON.parse(result);
					status = parsedRes.statusCode;
					break;
				default:
					status = '';
			}
		}
		return {
			status: status,
			options: {
				fileSize: '',
				timeToDownloadMs: 0
			}
		};
	}
}
